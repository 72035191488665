import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://bf95a82a6da675c264dd4d32269033e7@o4506536976449536.ingest.sentry.io/4506536981364736",
  integrations: [new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});